/* font family Raleway */

@import url("../css2");

/* font family Barlow */

@import url("../css2-1");

/*
Theme Name:     Digitly
Theme URI:      
Author:         
Author URI:     
Description:    
Version:        1.0
License:        
License URI:    

	Navigation Files

	01. Reset
	02. Global Style
	    [Table of Content]
	01. Index Page Style
        section Index Page
            1.1. Header section
            1.2. hero section
            1.3. card section
            1.4. logoipsum section
            1.5. About us section
            1.6. gallary section
            1.7. Services section
            1.8. Experience section
            1.10. Testimonials section
            1.9. How we work section
            1.11. team section
            1.12. Pricing section
            1.13. FAQ section
            1.14. Blogs section
            1.15. Footer section
    02. About Page Style
        section About Page
            2.2. hero section
            2.3. About us section
    03. Services Page Style
        section Services Page
            3.2. hero section
            3.3. Services card section
     04. Contact us Page Style
        section Contact us Page
            4.1 Contact from section
            4.2 Map section
    05. Testimonials Page Style
        section Testimonials Page
            5.1. Testimonials section
    06. 404 / Coming Soon Page Style
        section 404 Page
            6.1. 404 / Coming Soon page
 */

/* ============= 01. Reset section ============= */

html,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
}

/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
}

button:focus {
  outline: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

select::-ms-expand {
  display: none;
}

label {
  font-weight: normal;
}

iframe {
  width: 100%;
}

/* =====  End of 01. Reset  ====== */

/* ===== 02. Global Style ===== */

h1 {
  font-size: 75px;
  font-weight: 800;
  line-height: 85px;
  font-family: "Raleway", sans-serif;
}

h2 {
  font-size: 45px;
  font-weight: 800;
  line-height: 51px;
  font-family: "Raleway", sans-serif;
}

h3 {
  font-size: 25px;
  font-weight: 800;
  line-height: 31px;
  font-family: "Raleway", sans-serif;
}

h4 {
  font-size: 22px;
  font-weight: 800;
  line-height: 28px;
  font-family: "Raleway", sans-serif;
}

h5 {
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  font-family: "Raleway", sans-serif;
}

h6 {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  font-family: "Raleway", sans-serif;
}

p {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  font-family: "Barlow", sans-serif;
}

button {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  padding: 16px 38px 16px 38px;
  text-transform: capitalize;
  font-family: "Barlow", sans-serif;
}

a {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  padding: 16px 38px 16px 38px !important;
  text-transform: capitalize;
  font-family: "Barlow", sans-serif;
  text-decoration: none !important;
  transition: all 0.3s ease;
}

/* Button hover without background */

.btn_hover1 {
  display: inline-block;
  position: relative;
  overflow: hidden;
  border-radius: 50px;
  border: 1.5px solid #d12bec;
  color: #ffffff;
  z-index: 1;
  letter-spacing: 0.8px;
  background: linear-gradient(180deg, #ff7527 0%, #fabf28 100%);
}

.btn_hover1:hover {
  color: #ffffff;
}

.btn_hover1::before {
  border-radius: 50px;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-in;
  background: linear-gradient(42deg, #7436bb 0.01%, #b520a3 100%);
  z-index: -1;
  top: 0;
  right: 0;
}

.btn_hover1:hover::before {
  width: 0;
  background: linear-gradient(42deg, #7436bb 0.01%, #b520a3 100%);
}

/* button hover with background */

.btn_hover2 {
  display: inline-block;
  position: relative;
  overflow: hidden;
  border-radius: 50px;
  border: 1.5px solid #d12bec;
  color: #ffffff;
  z-index: 1;
  letter-spacing: 0.8px;
}

.btn_hover2::before {
  content: "";
  border-radius: 50px;
  position: absolute;
  width: 0;
  height: 100%;
  transition: all 0.3s ease-in;
  background: linear-gradient(180deg, #ff7527 0%, #fabf28 100%);
  z-index: -1;
  top: 0;
  left: 0;
}

.btn_hover2:hover::before {
  width: 100%;
  border-radius: 50px;
  background: linear-gradient(180deg, #ff7527 0%, #fabf28 100%);
}

/* button hover end */

/* animation icon */

@keyframes icon {
  0% {
    top: 0px;
  }
  50% {
    top: 10px;
  }
  100% {
    top: 0px;
  }
}

/* button back to top */

#backToTopBtn {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  border: none;
  padding: 17px 20px;
  border-radius: 10px;
  transition: all 0.3s ease;
  color: #d12bec;
}

#backToTopBtn:hover {
  color: #ffffff;
}

.h2_margin {
  margin: 20px 0;
}

/* =====  End of 02. Global style  ===== */

/* ====== 1.1. Header section ====== */

header {
  padding: 20px 0;
}

nav a {
  padding: 0 !important;
}

.header-index {
  background-image: url("../Images/bg/hero_bg.png");
  background-position: center center;
  background-size: cover;
}

nav img {
  width: 100px;
}

.navbar-nav {
  margin-left: 80px;
}

nav li a {
  color: #d3c9c1 !important;
}

nav li a.active {
  color: #f57c48 !important;
}

nav li a:hover {
  color: #f57c48 !important;
}

.dropdown-item:active {
  background-color: inherit !important;
}

.navbar .nav-item .dropdown-menu {
  display: none;
}

.navbar .dropdown:hover .dropdown-menu {
  display: block;
  width: 220px;
  padding: 15px 0px 15px 0px;
  border-radius: 10px;
  background-color: #3d0f41;
  margin-left: -52px;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: inherit;
}

.dropdown-toggle::after {
  vertical-align: middle;

  border: none;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: white !important;
  background-color: inherit;
}

.dropdown-menu li {
  display: block;
  position: relative;
  overflow: hidden;
  z-index: 1;
  color: #ffffff !important;
  padding: 10px 20px;
}

.dropdown-menu .dropdown-item::before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  transition: all 0.3s ease-in;
  background: #b520a3;
  z-index: -1;
  top: 0;
  left: 0;
}

.dropdown-menu .dropdown-item:hover::before {
  width: 100%;
  background: #b520a3;
}

.nav-right P:first-child {
  color: #d3c9c1;
}

.nav-right span {
  color: #f57c48;
}

.nav-right .fa-magnifying-glass {
  color: #ffffff;
  font-size: 22px;
  cursor: pointer;
}

.nav-right .fa-bars {
  color: #ffffff;
  font-size: 22px;
  background-color: #150803;
  padding: 7px 8px;
  border-radius: 50%;
  cursor: pointer;
}

.navbar-toggler {
  border: 1px solid #7436bb;
  background: linear-gradient(42deg, #7436bb 0.01%, #b520a3 100%);
  padding: 10px 20px;
}

.navbar-toggler:hover {
  background: inherit;
}

.navbar-toggler span {
  color: #ffffff;
  font-size: 22px;
}

/* sidebar */

aside {
  background-color: #3d0f41;
  z-index: 10;
  height: 100%;
}

.sidebar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}

.sidebar img {
  width: 100px;
}

.sidebar button {
  width: 44px;
  height: 44px;
  background: linear-gradient(42deg, #7436bb 0.01%, #b520a3 100%);
  padding: 10px 12px 10px 12px;
  margin-bottom: -15px;
  border-radius: 0.25em;
  border: 1.5px solid #b520a3;
  color: #ffffff;
}

.sidebar button:hover {
  background: inherit;
}

aside ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  margin-left: 25px;
}

aside ul li {
  margin: 5px 0;
}

aside li a {
  color: #d3c9c1 !important;
  transition: 500ms;
  padding: 10px 0 !important;
}

aside li a.active {
  color: #f57c48 !important;
}

aside li a:hover {
  color: #f57c48 !important;
  transition: 500ms;
}

.dropdown-item:active {
  background-color: white;
}

.collapse_btn {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.collapse_btn i {
  border: 1px solid #d3c9c1;
  padding: 7px 8px;
  border-radius: 40px;
}

.collapse_btn i:hover {
  border: 1px solid #f57c48;
}

.plus_collapse {
  margin: -52px 0px 0 0;
  height: 45px;
  margin-right: 25px;
}

aside .pages {
  font-weight: 500;
  color: #d3c9c1;
  margin: 11px 0 7px 0;
}

.sidepanel {
  width: 320px;
  position: fixed;
  height: 100vh;
  top: 0;
  left: -320px;
  overflow-x: hidden;
  transition: 0.5s;
  padding: 40px 20px 20px 20px;
}

.sidepanel::-webkit-scrollbar {
  width: 3px;
}

.sidepanel::-webkit-scrollbar-thumb {
  background: linear-gradient(42deg, #7436bb 0.01%, #b520a3 100%);
  border-radius: 10px;
}

/* search-bar */

#search-bar {
  background-color: #000000a6;
  width: 100%;
  height: 0;
  bottom: 0;
  z-index: 2;
  transition: all 0.5s ease;
  position: fixed;
}

#search-bar form {
  border-radius: 50px;
  border: 1px solid #6d2f8a;
  background: #381a46;
  width: 50%;
  margin: auto;
}

#search-bar form input {
  border-radius: 50px;
  background: #381a46;
  border: none;
  outline: none;
  padding: 10px 20px;
  color: #ffffff;
  width: 100%;
}

#search-bar form button {
  transition: all 0.3s ease;
  text-transform: uppercase;
}

#search-bar div:first-child {
  position: absolute;
  width: 44px;
  height: 44px;
  background: linear-gradient(42deg, #7436bb 0.01%, #b520a3 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25em;
  border: 1.5px solid #b520a3;
  color: #ffffff;
  margin: 30px;
  cursor: pointer;
}

#search-bar div:first-child:hover {
  background: inherit;
}

/* right-sidebar */

/* .right-sidbar {
  width: 355px;
  position: fixed;
  height: 100vh;
  top: 0;
  right: -355px;
  overflow-x: hidden;
  transition: 0.5s;
  padding: 60px 25px 20px 30px;
  z-index: 2;
  background: #d3c9c1;
}

.right-sidbar img {
  width: 100px;
}

.right-sidbar button {
  width: 44px;
  height: 44px;
  background: linear-gradient(42deg, #7436bb 0.01%, #b520a3 100%);
  padding: 7px 12px 10px 12px;
  border-radius: 0.25em;
  border: 1.5px solid #b520a3;
  color: #ffffff;
} */
/* 
.right-sidbar button:hover {
  background: linear-gradient(180deg, #ff7527 0%, #fabf28 100%);
}

.right-sidbar p {
  color: #231f20;
  margin: 25px 0;
}

.right-sidbar h4 {
  margin: 25px 0;
}

.right-sidbar hr {
  margin: 28px 0 0 0;
  border: 1px solid;
  opacity: 1;
  color: #000000;
}

.right-sidbar ul li {
  display: flex;
  gap: 20px;
}

.right-sidbar ul a {
  padding: 0 !important;
  color: #231f20;
}

.right-sidbar i {
  font-size: 20px;
  background: linear-gradient(0deg, #f54a48 0.5%, #fa982f 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.right-sidbar i:hover {
  background: linear-gradient(0deg, #f54a48 0.5%, #f54a48 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
} */

/* ====== End of 1.1. Header section ====== */

/* ====== 1.2. hero section ====== */

.hero {
  background-image: url("../Images/bg/hero.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 172px 0 279px 0;
}

.hero h1 {
  color: #ffffff;
  letter-spacing: 3.75px;
  text-transform: uppercase;
}

.hero h3 {
  color: #ffffff;
  text-transform: uppercase;
}

.hero-social-icon {
  position: absolute;
  left: 0;
  top: 0;
}

.hero-social-icon i {
  font-size: 26px;
  background: linear-gradient(0deg, #f54a48 0.5%, #fa982f 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-social-icon i:hover {
  background: linear-gradient(0deg, #f54a48 0.5%, #f54a48 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-social-icon hr {
  margin-top: 68px;
  opacity: 1;
  color: #ffffff;
  width: 110px;
  rotate: 90deg;
}

/* ====== End of 1.2. hero section ====== */

/* ====== 1.3. card section ====== */

.feature {
  background-color: #150725;
  color: "#";
  margin-top: -1px;
}

.feature .container .col-lg-3 {
  margin-top: -145px;
  display: flex;
}

.feature .container .col-lg-3 div {
  border-radius: 20px;
  background: linear-gradient(0deg, #471133 0%, #140921 100%);
  color: #ffffff;
  padding: 40px 30px;
  transition: all 0.4s ease;
}

.feature .container .col-lg-3 div:hover {
  transform: scale(1.05);
  transition: all 0.4s ease;
}

.feature .feature-img1 {
  width: 53px;
}

.feature img {
  width: 60px;
}

.feature h4 {
  width: 146px;
  letter-spacing: 1.1px;
  text-transform: uppercase;
  margin: 20px 0 12px 0;
}

.ab-feature {
  padding: 15px 0 90px 0;
}

.ab-fa-hr {
  color: #ffffff;
}

.ab-fa-hr h6 {
  letter-spacing: 3.72px;
  text-transform: uppercase;
}

.ab-fa-hr hr {
  opacity: 1.25;
  height: 1px;
  color: #ffffffbf;
  width: 60px;
  border: 1px solid;
}

.ab-feature .container .col-lg-3 {
  margin-top: 0;
}

/* ====== End of 1.3. card section ====== */

/* ====== 1.4. logoipsum section ====== */

.logoipsum {
  background-color: #150725;
  padding: 150px 0;
  margin-top: -1px;
}

.logoipsum-slider {
  margin-top: 24px;
}

.logoipsum h3 {
  letter-spacing: 1.25px;
  color: #ffffff;
  text-transform: uppercase;
}

.logoipsum img {
  width: 170px;
  margin: 0 auto;
}

.logoipsum .hr1 {
  opacity: 1;
  height: 2px;
  rotate: 90deg;
  width: 60px;
  margin: 0 auto 50px auto;
  background: rgba(255, 255, 255, 0.75);
}

.logoipsum .hr2 {
  opacity: 1;
  height: 2px;
  rotate: 90deg;
  width: 60px;
  margin: 50px auto 0 auto;
  background: rgba(255, 255, 255, 0.75);
}

/* ====== End of 1.4. logoipsum section ====== */

/* ====== 1.5. About us section ====== */

.about {
  background: #3d0f41;
  padding: 100px 0;
}

.about h5 {
  color: #ffffff;
  font-weight: 400;
  font-family: "Barlow", sans-serif;
}

.about h2 {
  color: #ffffff;
}

.about p {
  color: #ffffff;
}

.about hr {
  opacity: 1.25;
  height: 1px;
  color: #b520a3;
  width: 60px;
  border: 1px solid;
}

.about i {
  border: 2px solid #fa982f;
  border-radius: 50%;
  padding: 3px;
  font-size: 20px;
  background: linear-gradient(66.75deg, #f54a48 0.87%, #fa982f 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.about .position-relative {
  background-image: url("../Images/bg/about-bg.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.about-mask figure {
  background: linear-gradient(42deg, #7436bb 0.01%, #b520a3 100%);
  width: fit-content;
  clip-path: circle(45% at 50% 50%);
  transition: all 0.8s ease;
}

.about-mask figure:hover {
  transform: scale(1.05);
  transition: all 0.8s ease;
}

.about-mask img {
  width: 85%;
  display: block;
  margin: auto;
  clip-path: circle(48% at 50% 50%);
}

.about-hr {
  color: #ffffff;
  position: absolute;
  right: -50px;
  bottom: 25px;
  rotate: -90deg;
}

.about-hr h6 {
  letter-spacing: 3.72px;
  text-transform: uppercase;
}

.about-hr hr {
  opacity: 1.25;
  height: 1px;
  color: #ffffffbf;
  width: 60px;
  border: 1px solid;
}

.ab-count {
  background: linear-gradient(180deg, #ff7527 0%, #fabf28 100%);
  color: #ffffff;
  position: absolute;
  bottom: -18px;
  left: 118px;
  z-index: 11;
  border-radius: 50%;
  width: 175px;
  height: 175px;
}

.ab-count p {
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 600;
}

/* ====== End of 1.5. About us section ====== */

/* ====== 1.6. gallary section ====== */

.portfolio-1 {
  background: #150725;
  padding: 120px 0 120px 0;
  margin-top: -1px;
}

.portfolio-1 h5 {
  color: #ffffff;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.portfolio-1 h2 {
  color: #ffffff;
}

.portfolio-1 p {
  color: #d3c9c1;
  padding-right: 145px;
}

.portfolio-1 .gallary-hr1 {
  opacity: 1.25;
  height: 1px;
  color: #b520a3;
  width: 60px;
  border: 1px solid;
}

.portfolio-1 hr {
  opacity: 1.25;
  height: 1px;
  color: #461f72;
  border: 1px solid;
}

.portfolio-1 .btn_hover2 {
  border: 1px solid #ff7527;
}

.tab {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
}

.tab button {
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  background-color: inherit;
  color: #ffffff;
  border-radius: 10px;
}

.tab button.active {
  background: linear-gradient(11deg, #7436bb 0.01%, #b520a3 100%);
}

.tabcontent {
  display: none;
  padding: 6px 12px;
}

.gallary {
  background-image: url("../Images/bg/gallary-bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-bottom: 120px;
  position: relative;
  z-index: 1;
}

.ga-bg {
  background-color: #150725;
  height: 300px;
}

.gallary span {
  background-color: #150725;
  opacity: 0.8;
  width: 100% !important;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
}

.gallary .container {
  margin-top: -390px;
  z-index: 1;
  position: relative;
}

.gallary .mt {
  margin-top: -245px;
}

.gallary img {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.gallary img[alt="sm_img"] {
  height: 200px;
}

.gallary-hr h6 {
  letter-spacing: 3.72px;
  text-transform: uppercase;
  color: #ffffff;
}

.gallary-hr hr {
  opacity: 1;
  color: #ffffff;
  height: 2px;
  width: 60px;
}

/* ====== End of 1.6. gallary section ====== */

/* ====== 1.7. Services section ====== */

.service-1 {
  background: #3d0f41;
  padding: 120px 0;
}

.service-1 h5 {
  color: #ffffff;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.service-1 h2 {
  color: #ffffff;
}

.service-1 .col-lg-8 p {
  padding: 0 221px 0 0;
}

.service-1 p {
  color: #d3c9c1;
  position: relative;
  margin-bottom: 15px;
}

.service-1 .service-hr1 {
  opacity: 1.25;
  height: 1px;
  color: #b520a3;
  width: 60px;
  border: 1px solid;
}

.service-1 hr {
  opacity: 1.25;
  height: 1px;
  color: #461f72;
  border: 1px solid;
}

.service-1 .btn_hover2 {
  border: 1px solid #ff7527;
}

.service-card {
  border: 1px solid #b520a3;
  background: linear-gradient(11deg, #7436bb 0.01%, #b520a3 100%);
  box-shadow: 8px 18px 30px 0px rgba(0, 0, 0, 0.2);
  padding: 40px 40px 30px 40px;
  width: 100%;
}

.service-card:hover {
  transform: scale(1.05) !important;
  transition: all 0.4s ease !important;
}

.service-card a {
  color: #d3c9c1;
  display: flex;
  align-items: center;
  gap: 5px;
}

.service-card a:hover {
  font-weight: 700;
  color: #ffffff;
  gap: 8px;
}

.service-card h3 {
  margin: 20px 0;
  color: #ffffff;
  letter-spacing: 1.25px;
}

.service-card img {
  object-fit: cover;
  margin: auto;
  display: block;
}

.service-card figure {
  background: #ffffff;
  box-shadow: 10px 17px 28px 0px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 12px;
  width: 124px;
  height: 85px;
  display: flex;
}

.service-card img[alt="slider1"] {
  width: 105px;
}

.service-card img[alt="slider2"] {
  width: 75px;
}

.service-card img[alt="slider3"] {
  width: 60px;
}

/* ====== End of 1.7. Services section ====== */

/* ====== 1.8. Experience section ====== */

.experience {
  background-image: url("../Images/bg/experience_bg.jpg");
  background-size: cover;
  background-position: center center;
  padding: 120px 0;
  position: relative;
  margin-top: -1px;
}

.experience::before {
  position: absolute;
  content: "";
  top: 26rem;
  background-image: url("../Images/bg/experience_cercle.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  width: 30%;
  height: 39%;
  left: 32%;
}

.experience h5 {
  color: #ffffff;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.experience h2 {
  color: #ffffff;
}

.experience p {
  color: #d3c9c1;
}

.experience .col-lg-8 p {
  padding: 0 221px 0 0;
}

.experience .experience-hr1 {
  opacity: 1.25;
  height: 1px;
  color: #b520a3;
  width: 60px;
  border: 1px solid;
}

.experience hr {
  opacity: 1.25;
  height: 1px;
  color: #461f72;
  border: 1px solid;
  margin: 35px 0;
}

.experience .btn_hover2 {
  border: 1px solid #ff7527;
}

.experience .col-lg-6 {
  z-index: 1;
}

.experience_mask figure {
  width: fit-content;
  clip-path: circle(47% at 50% 50%);
  padding: 6px;
  background: linear-gradient(11deg, #7436bb 0.01%, #b520a3 100%);
  transition: all 0.8s ease;
}

.experience_mask figure:hover {
  transform: scale(1.05);
  transition: all 0.8s ease;
}

.experience_mask img {
  width: 100%;
  clip-path: circle(47% at 50% 50%);
}

.experience-hr {
  color: #ffffff;
  position: absolute;
  bottom: -220px;
  left: -28px;
  bottom: 25px;
  rotate: -90deg;
}

.experience-hr h6 {
  letter-spacing: 3.72px;
  text-transform: uppercase;
}

.experience-hr hr {
  opacity: 1.25;
  height: 1px;
  color: #ffffffbf;
  width: 60px;
  border: 1px solid;
  margin: 0;
}

.ex-card {
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 23px 30px 0px rgba(0, 0, 0, 0.15);
  padding: 20px 20px 20px 20px;
}

.ex-card:hover {
  background: linear-gradient(11deg, #7436bb 0.01%, #b520a3 100%);
  transform: scale(1.05) !important;
  transition: all 0.4s ease !important;
}

.ex-card:hover h4 {
  color: #ffffff;
}

.ex-card:hover p {
  color: #ffffff;
}

.ex-card .d-flex {
  border-radius: 50%;
  background: linear-gradient(11deg, #7436bb 0.01%, #b520a3 100%);
  color: #ffffff;
  width: 115px;
  padding: 29px 0px;
}

.ex-card:hover.ex-card .d-flex {
  background: #ffffff;
}

.ex-card:hover.ex-card h3 {
  color: #7436bb !important;
}

.ex-card div:last-child {
  width: 100%;
}

.ex-card h4 {
  color: #231f20;
}

.ex-card p {
  color: #767676;
  margin-top: 10px;
}

/* for about page */

.ab-experience {
  background-image: url("../Images/bg/ab_experience_bg.png") !important;
  background-size: cover !important;
  background-position: center !important;
  margin-top: -2px;
}

/* ====== End of 1.8. Experience section ====== */

/* ====== 1.9. How we work section ====== */

.work {
  background-image: url("../Images/bg/work-bg.jpg");
  background-size: cover;
  background-position: center center;
  padding: 120px 0;
  margin-top: -2px;
}

.work h5 {
  letter-spacing: 1px;
}

.work-para {
  color: #ffffff;
  padding: 0 290px;
}

.work-line {
  background-image: url("../Images/bg/work-line.png");
  background-size: 100% 100%;
  background-position: center;
  min-height: 242px;
  background-repeat: no-repeat;
  margin: 146px 25px 146px 25px;
}

.work-line div {
  position: absolute;
  border-radius: 50%;
  background: linear-gradient(11deg, #7436bb 0.01%, #b520a3 100%);
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  padding: 18px 0 32px 34px;
}

.work-line div:first-child {
  top: -66px;
  left: -30px;
  width: 196px;
  min-height: 196px;
}

.work-line div:nth-child(2) {
  bottom: -70px;
  left: 270px;
  width: 220px;
  min-height: 200px;
}

.work-line div:nth-child(3) {
  right: 260px;
  top: -66px;
  width: 196px;
  min-height: 196px;
}

.work-line div:last-child {
  right: -49px;
  bottom: -70px;
  width: 230px;
  min-height: 200px;
}

.work a {
  border: 1.5px solid #ff7527;
}

.work-hr {
  color: #ffffff;
  position: absolute;
  right: 0;
  rotate: -90deg;
}

.work-hr h6 {
  letter-spacing: 3.72px;
  text-transform: uppercase;
}

.work-hr hr {
  opacity: 1.25;
  height: 1px;
  color: #ffffffbf;
  width: 60px;
  border: 1px solid;
}

/* ====== End of 1.9. How we work section ====== */

/* ====== 1.10. Testimonials section ====== */

.testimonial {
  background: #150725;
  padding: 120px 0;
  color: #ffffff;
}

.testimonial_hr {
  color: #ffffff;
  position: absolute;
  rotate: -90deg;
}

.testimonial_hr h6 {
  letter-spacing: 3.72px;
  text-transform: uppercase;
}

.testimonial_hr hr {
  opacity: 1.25;
  height: 1px;
  color: #ffffffbf;
  width: 60px;
  border: 1px solid;
}

.testimonial .position-relative h3 {
  margin-top: 147px;
}

.testimonial_slider {
  background-image: url("../Images/bg/testimonial_bg.png");
  background-repeat: no-repeat;
  background-size: 17% 77%;
  padding: 16px 0 0 0;
  background-position-x: center;
}

.testimonial_slider p {
  color: #d3c9c1;
  padding: 0 235px;
  margin-bottom: 27px;
}

.testimonial_slider h6 {
  color: #ff8c4b;
  margin-top: 5px;
  letter-spacing: 5px;
  font-family: "Barlow", sans-serif;
}

.testimonial_slider h5 {
  letter-spacing: 3px;
}

.agency hr {
  opacity: 1.25;
  height: 1px;
  color: #ffffffbf;
  width: 60px;
  border: 1px solid;
  rotate: 90deg;
}

.agency .agency-hr1 {
  margin: 120px auto 0 auto;
}

.agency .agency-hr2 {
  margin: 60px auto 0 auto;
}

.star-slider {
  display: flex;
  justify-content: center;
}

.star-slider h3 {
  text-wrap: nowrap;
}

.agency i {
  color: #fabf28;
  margin-left: 75px;
}

/* ====== End of 1.10. Testimonials section ====== */

/* ====== 1.11. team section ====== */

.team {
  background-image: url("../Images/bg/experience_bg.jpg");
  background-size: cover;
  background-position: center center;
  padding: 120px 0 0 0;
}

#team {
  background-image: url("../Images/bg/experience_bg.jpg");
  background-size: cover;
  background-position: center center;
  padding: 120px 0 120px 0;
}

.team h5 {
  color: #ffffff;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.team h2 {
  color: #ffffff;
  padding: 0 212px 0 0;
}

.team p {
  color: #d3c9c1;
  padding: 0 184px 0 0;
}

.team .team-hr1 {
  opacity: 1.25;
  height: 1px;
  color: #b520a3;
  width: 60px;
  border: 1px solid;
}

.team a {
  border: 1.5px solid #ff8c4b;
}

.team-img {
  width: 100%;
  object-fit: cover;
  position: relative;
}

.team-img img {
  width: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(180deg, #7436bb66 28%, #b520a378 68%);
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: 0.5s ease;
}

.team-img:hover .overlay {
  height: 100%;
}

.overlay_text {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #ffffff;
}

.overlay_text h4 {
  margin: 6px 0 15px 0;
}

.overlay_text i {
  color: #ffffff;
  font-size: 20px;
}

.overlay_text a {
  border: 1px solid #ffffff;
  border-radius: 50%;
}

.overlay_text a:hover {
  background-color: #ffffff;
}

.overlay_text a:hover i {
  color: #150725;
}

.overlay_text .d-flex a:first-child {
  padding: 8px 13px !important;
}

.overlay_text .d-flex a:nth-child(2) {
  padding: 9px 12px !important;
}

.overlay_text .d-flex a:last-child {
  padding: 8px 10px !important;
}

.team-btn {
  background: linear-gradient(0deg, #471133 0%, #140921 100%);
  color: #d3c9c1;
  padding: 40px 20px;
  margin-top: 90px;
  text-transform: uppercase;
}

/* ====== End of 1.11. team section ====== */

/* ====== 1.12. Pricing section ====== */

.pricing {
  padding: 120px 0;
  background-image: url("../Images/bg/pricing-bg.png");
  background-size: cover;
  background-position: center center;
  color: #ffffff;
  z-index: 1;
}

#pricing {
  background: #150725;
  padding: 120px 0;
}

.pricing span {
  background-color: #150725;
  opacity: 0.9;
  width: 100% !important;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
}

.pricing-hr {
  color: #ffffff;
  position: absolute;
  right: 0;
  rotate: -90deg;
}

.pricing-hr h6 {
  letter-spacing: 3.72px;
  text-transform: uppercase;
}

.pricing-hr hr {
  opacity: 1.25;
  height: 1px;
  color: #ffffffbf;
  width: 60px;
  border: 1px solid;
}

.pricing p {
  padding: 0 330px;
}

.pricing-card div {
  background: #3d0f41;
  color: #ffffff;
  padding: 70px;
  transition: all 0.4s ease;
  width: 100%;
}

.pricing-card div:hover {
  transform: scale(1.07) !important;
  transition: all 0.4s ease !important;
  z-index: 3;
}

.pricing-card div:nth-child(2) {
  transform: scale(1.09);
  z-index: 2;
  background: linear-gradient(11deg, #7436bb 0.01%, #b520a3 100%);
}

.pricing-card div:nth-child(2):hover {
  transform: scale(1.05);
  z-index: 2;
}

.pricing-card h3 {
  color: #f57c48;
}

.pricing-card h2 {
  margin-bottom: 30px;
}

.pricing-card div:nth-child(2) a {
  background-color: #ffffff;
  color: #b520a3;
}

.pricing-card div:nth-child(2) a:hover {
  color: #ffffff;
}

.pricing-card a {
  margin-top: 20px;
}

/* ====== End of 1.12. Pricing section ====== */

/* ====== 1.13. FAQ section ====== */

.faq {
  background-image: url("../Images/bg/faq-bg.png");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  padding: 120px 0;
  z-index: 1;
}

#faq {
  background: #150725;
  padding: 120px 0;
}

.faq span {
  background-color: #000000;
  opacity: 0.6;
  width: 100% !important;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
}

.faq .text-center h2 {
  color: #ffffff;
  padding: 0 55px 0 0;
}

.faq h5 {
  color: #ffffff;
}

.faq .text-center p {
  color: #ffffff;
  padding: 0 123px 0 0;
}

.faq .faq-hr1 {
  opacity: 1.25;
  height: 1px;
  color: #b520a3;
  width: 60px;
  border: 1px solid;
}

.accordion p {
  color: #767676;
}

.accordion button {
  color: #231f20;
  font-weight: 700 !important;
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  background-color: #7436bb69;
  color: #000000;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("../Images/icon/menus.png");
  background-position: center center;
}

.accordion-button::after {
  background-image: url("../Images/icon/plus.png");
}

.accordion-item:first-of-type {
  border-radius: 0;
}

.accordion-item:last-of-type {
  border-radius: 0;
}

/* ====== End of 1.13. FAQ section ====== */

/* ====== 1.14. Blogs section ====== */

.blog {
  background: #150725;
  padding: 120px 0;
}

.blog h5 {
  color: #ffffff;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.blog h2 {
  color: #ffffff;
}

.blog p {
  color: #d3c9c1;
  padding: 0 296px 0 0;
}

.blog .blog-hr1 {
  opacity: 1.25;
  height: 1px;
  color: #b520a3;
  width: 60px;
  border: 1px solid;
}

.blog .row a {
  border: 1.5px solid #ff8c4b;
}

.blog-card {
  flex: 0 0 33.33%;
  overflow: hidden;
  padding-bottom: 20px;
}

.blog-card img {
  width: 100%;
  transition: all 0.4s ease;
}

.blog-card:hover img {
  transform: scale(1.05);
  opacity: 0.8;
  transition: all 0.4s ease;
}

.blog-card h4 {
  color: #d3c9c1;
  margin: 18px 0;
  padding: 0 30px 0 0;
}

.blog-card a {
  padding: 0 0 11px 0 !important;
  border-bottom: 2px solid #ff8c4b;
  letter-spacing: 2px;
  background: linear-gradient(66.75deg, #f54a48 0.87%, #fa982f 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* ====== End of 1.14. Blogs section ====== */

/* ====== 1.15. Footer section ====== */

footer {
  background: #150725;
  padding: 120px 0 0 0;
}

.footer-hr .d-flex {
  rotate: -90deg;
}

.footer-hr h6 {
  color: #ffffff;
  letter-spacing: 3.72px;
  text-transform: uppercase;
}

.footer-hr h3 {
  margin-top: 125px;
  color: #ffffff;
  letter-spacing: 1px;
}

.footer-hr p {
  color: #ffffff;
  padding: 0 300px;
  margin: 20px 0 30px 0;
}

.footer-hr hr {
  opacity: 1.25;
  height: 1px;
  color: #ffffffbf;
  width: 60px;
  border: 1px solid;
}

footer form {
  border-radius: 50px;
  border: 1px solid #6d2f8a;
  background: #381a46;
  width: 76%;
  margin: auto;
}

footer form input {
  border-radius: 50px;
  background: #381a46;
  border: none;
  outline: none;
  padding: 10px 20px;
  color: #ffffff;
  width: 68%;
}

footer form button {
  transition: all 0.3s ease;
  text-transform: uppercase;
}

footer form button:hover {
  color: #7436bb;
}

#footer-message {
  display: none;
  background: linear-gradient(42deg, #7436bb 0.01%, #b520a3 100%);
  color: white;
  width: 90%;
  height: 42%;
  padding: 120px 0 120px 0;
  font-size: 20px;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  border-radius: 10px;
}

.footer-menu {
  background: linear-gradient(0deg, #471133 0%, #140921 100%);
  color: #ffffff;
  padding: 50px 0;
  margin-top: 77px;
}

.footer-menu img {
  width: 100px;
  filter: brightness(0) invert(1);
}

.footer-menu i {
  font-size: 20px;
  background: linear-gradient(0deg, #f54a48 0.5%, #fa982f 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.footer-menu i:hover {
  background: linear-gradient(0deg, #f54a48 0.5%, #f54a48 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.footer-menu h4 {
  color: #f57c48;
  margin-bottom: 26px;
}

.footer-menu a {
  color: #ffffff;
  padding: 0 !important;
}

.footer-menu .col-lg-4 li {
  display: flex;
  gap: 20px;
}

.footer-menu p {
  padding: 0 78px 0 0;
}

.copyright {
  background: #150725;
  color: #ffffff;
  padding: 20px 0;
}

.copyright a {
  color: #ffffff;
}

/* ====== End of 1.15. Footer section ====== */

/* ====== 2.2. hero section ====== */

.header-about {
  padding-bottom: 70px;
  background: linear-gradient(0deg, #471133 0%, #0b0314 100%);
}

.header-about h2 {
  color: #ffffff;
  text-align: center;
  padding-top: 40px;
}

/* ====== End of 2.2. hero section ====== */

/* ====== 2.3. About us section ====== */

.ab-page {
  background: #150725;
}

.about_img {
  background: #150725;
}

.about_img img {
  border-radius: 20px;
  width: 90%;
  margin-top: -60px;
}

/* ====== End of 2.3. About us section ====== */

/* ====== 3.2. hero section ====== */

.header-service {
  padding-bottom: 68px;
  background: linear-gradient(0deg, #471133 0%, #0b0314 100%);
}

.header-service h2 {
  color: #ffffff;
  text-align: center;
  margin-top: 50px;
}

.serv-hero a {
  color: #ffffff;
  padding: 22px !important;
}

.serv-hero span {
  color: #ff7527;
  font-size: 20px;
}

/* ====== End of 3.2. hero section ====== */

/* ====== 3.3. Services card section ====== */

.service-page {
  background: #150725;
  padding: 120px 0;
}

.service-page p {
  color: #d3c9c1;
  position: relative;
  margin-bottom: 15px;
}

.service-page .col-lg-4 {
  margin-bottom: 25px;
}

.service-page .col-lg-4 div {
  border: 1px solid #b520a3;
  background: linear-gradient(11deg, #7436bb 0.01%, #b520a3 100%);
  box-shadow: 8px 18px 30px 0px rgba(0, 0, 0, 0.2);
  padding: 40px 40px 30px 40px;
  transition: all 0.4s ease;
}

.service-page .col-lg-4 div:hover {
  transform: scale(1.05);
  transition: all 0.4s ease;
}

.service-page .col-lg-4 div a {
  color: #d3c9c1;
  display: flex;
  align-items: center;
  gap: 5px;
}

.service-page .col-lg-4 div a:hover {
  font-weight: 700;
  color: #ffffff;
  gap: 8px;
}

.service-page .col-lg-4 div h3 {
  margin: 20px 0;
  color: #ffffff;
  letter-spacing: 1.25px;
}

.service-page .col-lg-4 div img {
  object-fit: cover;
  margin: auto;
  display: block;
}

.service-page .col-lg-4 div figure {
  background: #ffffff;
  border-radius: 10px;
  padding: 12px;
  width: 124px;
  height: 85px;
  display: flex;
}

.service-page .col-lg-4 div img[alt="slider1"] {
  width: 105px;
}

.service-page .col-lg-4 div img[alt="slider2"] {
  width: 75px;
}

.service-page .col-lg-4 div img[alt="slider3"] {
  width: 60px;
}

.service-page .col-lg-4 div img[alt="slider4"] {
  width: 90px;
}

.service-page .col-lg-4 div img[alt="slider5"] {
  width: 42px;
}

.service-page .col-lg-4 div img[alt="slider6"] {
  width: 35px;
}

.service-page .col-lg-4 div img[alt="slider7"] {
  width: 55px;
}

.service-page .col-lg-4 div img[alt="slider8"] {
  width: 73px;
}

.service-page .col-lg-4 div img[alt="slider9"] {
  width: 66px;
}

/* ====== End of 3.3. Services card section ====== */

/* ====== 4.1 Contact from section ====== */

.contact {
  background: #150725;
  padding: 0 0 41px 0;
}

.contact form {
  border-radius: 20px;
  background: #26123c;
  padding: 50px 64px;
  margin: -90px auto 0 auto;
  width: 80%;
}

.contact input {
  width: 100%;
  padding: 17px 0;
  border: none;
  outline: none;
  border-bottom: 2px solid #ffffff;
  color: #ffffff;
  background-color: inherit;
}

.contact textarea {
  width: 100%;
  padding: 17px 0;
  border: none;
  border-bottom: 2px solid #ffffff;
  outline: none;
  color: #ffffff;
  background-color: inherit;
}

.contact form input::placeholder {
  color: #ffffff;
}

.contact form textarea::placeholder {
  color: #ffffff;
}

.contact-address a {
  color: #ffffff;
}

.contact-address i {
  color: #ffffff;
  background: linear-gradient(0deg, #ff7527 0.5%, #fa982f 100%);
  padding: 18px;
  border-radius: 50%;
  font-size: 19px;
}

.contact-address i:hover {
  color: #ff7527;
  background: #ffffff;
}

.contact-address hr {
  color: #878787;
  opacity: 1.25;
  rotate: 90deg;
  width: 76px;
}

.contact-address {
  margin-top: 90px;
}

.contact h2 {
  color: #ffffff;
  text-align: center;
  margin-top: 95px;
}

/* ====== End of 4.1 Contact from section ====== */

/* ====== 4.2 Map section ====== */

.mapouter {
  width: 100%;
  height: 470px;
}

.gmap_iframe {
  height: 470px;
}

/* ====== End of 4.2 Map section ====== */

/* ====== 5.1. Testimonials section ======  */

#testimonial {
  background: #150725;
  padding: 120px 0;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 80px;
}

.testimonial_page {
  background-image: url("../Images/bg/testimonial_bg.png");
  background-repeat: no-repeat;
  background-size: 17% 77%;
  padding: 16px 0 0 0;
  background-position-x: center;
}

.testimonial_page p {
  color: #d3c9c1;
  padding: 0 235px;
  margin-bottom: 27px;
}

.testimonial_page h6 {
  color: #ff8c4b;
  margin-top: 5px;
  font-family: "Barlow", sans-serif;
  letter-spacing: 5px;
}

.testimonial_page h5 {
  letter-spacing: 3px;
}

/* ====== End of 5.1. Testimonials section ======  */

/* ====== 6.1. 404 / Coming Soon page ====== */

.coming-soon {
  background: linear-gradient(0deg, #471133 0%, #0b0314 100%);
  color: #ffffff;
  min-height: 100vh;
}

.coming-soon img {
  width: 100%;
}

.coming-soon h2 {
  color: #ffc300;
  margin-top: -55px;
}

.coming-soon p {
  padding: 0 348px;
}

/* ====== End of 6.1. 404 / Coming Soon page ====== */
